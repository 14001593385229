import { __awaiter, __decorate, __extends, __generator } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isCommentsOpen, shouldOpenCommentsOnLoad, hasNewComments, canShowComments, } from 'widget/selectors/comments';
import { setCommentsOpen, setHasNewComments, setOpenCommentsOnLoad, } from 'widget/redux/client/actions/comments.js';
import { isSingleVideo, getLocale, isRTL } from 'shared/selectors/app-settings';
import { getSiteUrl, getMetaSiteId, } from 'widget/redux/client/hydrated-data/hydrated-data';
import { logBi } from 'shared/worker/actions/bi';
import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import { getAuthToken } from 'api/pix-wix-proxy/annoto/get-auth-token';
import { requestLogin, logOutCurrentMember } from 'shared/utils/auth';
import { notForPreview } from 'shared/utils/not-for-preview';
import { storeForReload } from 'widget/utils/reload';
import { isInFullScreen } from 'shared/selectors/player';
import { setSavedTime } from 'widget/redux/client/actions/player/set-saved-time';
import { playVideo, pauseVideo, } from 'widget/redux/client/actions/player/change-playback-status';
var AnnotoHandlers = /** @class */ (function (_super) {
    __extends(AnnotoHandlers, _super);
    function AnnotoHandlers() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        //show/hide events
        _this.handleUxEvent = function (_a) {
            var name = _a.name;
            var setCommentsOpen = _this.props.setCommentsOpen;
            if (name === 'widget:hide') {
                setCommentsOpen(false);
                _this.logShownBi(false);
            }
            if (name === 'widget:show') {
                setCommentsOpen(true);
                _this.logShownBi(true);
            }
        };
        _this.logShownBi = function (isShown) {
            var _a = _this.props, isSingleVideo = _a.isSingleVideo, videoItem = _a.videoItem, channelData = _a.channelData;
            _this.props.logBi('widget.vid.showComment.shown', {
                isShown: isShown,
                isSingleVideo: isSingleVideo,
                channelID: channelData.id,
                videoID: videoItem.id,
                source: videoItem.videoSource,
            });
        };
        // metadata update contains info about unread messages
        _this.handleMetadataChange = function (_a) {
            var comments = _a.comments;
            var _b = _this.props, videoItem = _b.videoItem, channelData = _b.channelData, isSingleVideo = _b.isSingleVideo, shouldOpenCommentsOnLoad = _b.shouldOpenCommentsOnLoad, setCommentsOpen = _b.setCommentsOpen, hasNewComments = _b.hasNewComments, setHasNewComments = _b.setHasNewComments;
            var isNewCommentsAdded = comments.countDiff > 0;
            if (isNewCommentsAdded && !hasNewComments) {
                _this.props.logBi('widget.vid.newComment.added', {
                    isSingleVideo: isSingleVideo,
                    channelID: channelData.id,
                    videoID: videoItem.id,
                    source: videoItem.videoSource,
                });
            }
            setHasNewComments(isNewCommentsAdded);
            if (shouldOpenCommentsOnLoad) {
                setCommentsOpen(true);
            }
        };
        //used to uniquely identify video in Annoto
        _this.getVideoId = function () { return _this.props.videoItem.id; };
        _this.getMediaDetails = function () {
            var _a = _this.props, videoItem = _a.videoItem, channelData = _a.channelData;
            return {
                title: _.get(videoItem, 'title', ''),
                description: _.get(videoItem, 'description', ''),
                group: {
                    id: channelData.id,
                    title: _.get(channelData, 'title', ''),
                    description: _.get(channelData, 'description', ''),
                },
            };
        };
        _this.handleLoginRequest = function () {
            _this.makeLoginRequest();
            return Promise.resolve();
        };
        _this.makeLoginRequest = notForPreview(function () {
            var _a = _this.props, player = _a.player, currentSiteUser = _a.currentSiteUser, isSingleVideo = _a.isSingleVideo, videoItem = _a.videoItem, channelData = _a.channelData, isInFullScreen = _a.isInFullScreen;
            if (currentSiteUser) {
                _this.setAnnotoUser(currentSiteUser);
            }
            else {
                _this.savePlayerTime();
                if (isInFullScreen) {
                    player.exitFullScreen();
                }
                _this.props.requestLogin();
            }
            _this.props.logBi('widget.vid.commentsLogin.requested', {
                isSingleVideo: isSingleVideo,
                channelID: channelData.id,
                videoID: videoItem.id,
                source: videoItem.videoSource,
            });
        });
        _this.handleLogoutRequest = function () {
            _this.savePlayerTime();
            _this.props.logOutCurrentMember();
            return Promise.resolve();
        };
        return _this;
    }
    AnnotoHandlers.prototype.componentDidMount = function () {
        var _a = this.props, player = _a.player, mobileMode = _a.mobileMode, locale = _a.locale, siteUrl = _a.siteUrl, currentSiteUser = _a.currentSiteUser, isRTL = _a.isRTL;
        player.configureAnnoto({
            clientId: ANNOTO_CLIENT_ID,
            locale: locale,
            ssoAuthRequestHandle: this.handleLoginRequest,
            logoutRequestHandle: this.handleLogoutRequest,
            mediaDetailsHandle: this.getMediaDetails,
            metadataChangeCallback: this.handleMetadataChange,
            uxEventCallback: this.handleUxEvent,
            mediaIdGetter: this.getVideoId,
            rtl: isRTL,
            deviceDetector: {
                isSmallScreen: function () { return mobileMode; },
                isPhone: function () { return mobileMode; },
                isTablet: function () { return mobileMode; },
                isDesktop: function () { return !mobileMode; },
            },
            originProvider: {
                getPageUrl: function () { return siteUrl; },
            },
        });
        if (currentSiteUser) {
            this.setAnnotoUser(currentSiteUser);
        }
        this.updatePlayerTime();
        this.changeAnnotoState();
    };
    AnnotoHandlers.prototype.componentDidUpdate = function (prevProps) {
        var _a = this.props, isCommentsOpen = _a.isCommentsOpen, player = _a.player, canShowComments = _a.canShowComments, savedTime = _a.savedTime;
        if (prevProps.canShowComments !== canShowComments) {
            this.changeAnnotoState();
        }
        if (isCommentsOpen && !prevProps.isCommentsOpen) {
            player.showAnnoto();
            this.logShownBi(true);
        }
        if (prevProps.savedTime !== savedTime) {
            this.updatePlayerTime();
        }
    };
    AnnotoHandlers.prototype.changeAnnotoState = function () {
        var _a = this.props, player = _a.player, canShowComments = _a.canShowComments;
        if (canShowComments) {
            player.enableAnnoto();
        }
        else {
            player.disableAnnoto();
        }
    };
    AnnotoHandlers.prototype.setAnnotoUser = function (currentSiteUser) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, player, metaSiteId, token;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, player = _a.player, metaSiteId = _a.metaSiteId;
                        return [4 /*yield*/, getAuthToken(currentSiteUser.id, metaSiteId)];
                    case 1:
                        token = _b.sent();
                        player.setAnnotoAuthToken(token);
                        return [2 /*return*/];
                }
            });
        });
    };
    AnnotoHandlers.prototype.savePlayerTime = function () {
        var player = this.props.player;
        this.props.storeForReload({
            savedPlayerTime: player.getCurrentTime(),
        });
    };
    AnnotoHandlers.prototype.updatePlayerTime = function () {
        var _a = this.props, player = _a.player, pauseVideo = _a.pauseVideo, playVideo = _a.playVideo, savedTime = _a.savedTime, videoItem = _a.videoItem, setSavedTime = _a.setSavedTime, setOpenCommentsOnLoad = _a.setOpenCommentsOnLoad;
        if (!player || !savedTime) {
            return;
        }
        // restoring playback to where user stopped before login from Annoto
        player.seekTo(savedTime);
        setOpenCommentsOnLoad(true);
        setSavedTime(0);
        playVideo(videoItem.id);
        pauseVideo(videoItem.id);
    };
    AnnotoHandlers.prototype.render = function () {
        return null;
    };
    AnnotoHandlers.propTypes = {
        player: PropTypes.object.isRequired,
        isCommentsOpen: PropTypes.bool.isRequired,
        setCommentsOpen: PropTypes.func.isRequired,
        setHasNewComments: PropTypes.func.isRequired,
        isSingleVideo: PropTypes.bool.isRequired,
        shouldOpenCommentsOnLoad: PropTypes.bool.isRequired,
        hasNewComments: PropTypes.bool.isRequired,
        channelData: PropTypes.object.isRequired,
        videoItem: PropTypes.object.isRequired,
        mobileMode: PropTypes.bool.isRequired,
        locale: PropTypes.string.isRequired,
        siteUrl: PropTypes.string.isRequired,
        currentSiteUser: PropTypes.object,
        isInFullScreen: PropTypes.bool.isRequired,
        canShowComments: PropTypes.bool.isRequired,
        playVideo: PropTypes.func.isRequired,
        pauseVideo: PropTypes.func.isRequired,
        setSavedTime: PropTypes.func.isRequired,
        setOpenCommentsOnLoad: PropTypes.func.isRequired,
        savedTime: PropTypes.number,
        isRTL: PropTypes.bool,
        metaSiteId: PropTypes.string,
    };
    AnnotoHandlers = __decorate([
        connect(function (state, ownProps) {
            var videoItem = _.get(ownProps, 'videoItem');
            var channelData = _.get(ownProps, 'channelData');
            var currentSiteUser = getCurrentSiteUser(state);
            var metaSiteId = getMetaSiteId(state);
            return {
                isCommentsOpen: isCommentsOpen(state),
                isSingleVideo: isSingleVideo(state),
                shouldOpenCommentsOnLoad: shouldOpenCommentsOnLoad(state),
                hasNewComments: hasNewComments(state),
                locale: getLocale(state),
                siteUrl: getSiteUrl(state),
                currentSiteUser: getCurrentSiteUser(state),
                isInFullScreen: isInFullScreen(state),
                canShowComments: canShowComments(state, videoItem, channelData, currentSiteUser),
                savedTime: state.player.savedTime,
                isRTL: isRTL(state),
                metaSiteId: metaSiteId,
            };
        }, {
            setCommentsOpen: setCommentsOpen,
            setHasNewComments: setHasNewComments,
            playVideo: playVideo,
            pauseVideo: pauseVideo,
            setSavedTime: setSavedTime,
            setOpenCommentsOnLoad: setOpenCommentsOnLoad,
            logBi: logBi,
            requestLogin: requestLogin,
            logOutCurrentMember: logOutCurrentMember,
            storeForReload: storeForReload,
        })
    ], AnnotoHandlers);
    return AnnotoHandlers;
}(React.Component));
export default AnnotoHandlers;
