import _ from 'lodash';
import CSSModules from './react-css-modules';
export var VODCSSModules = function (styles) { return function (WrappedComponent) {
    if (!styles) {
        return _.identity;
    }
    return CSSModules(WrappedComponent, styles, {
        allowMultiple: true,
        handleNotFoundStyleName: 'ignore',
    });
}; };
