import { __awaiter, __extends, __generator } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import WixSDK from 'shared/utils/wix-sdk';
import { getVodSettings } from 'api/private/get-vod-settings';
import { vodSettingsService } from 'api/v3/private/settings';
var PremiumIntentUpdater = /** @class */ (function (_super) {
    __extends(PremiumIntentUpdater, _super);
    function PremiumIntentUpdater() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PremiumIntentUpdater.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var vodSettings;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, (this.props.isV3
                            ? vodSettingsService.get()
                            : getVodSettings())];
                    case 1:
                        vodSettings = _a.sent();
                        WixSDK.Settings.appEngaged(vodSettings.biUserUploadedFiles
                            ? WixSDK.Settings.PremiumIntent.PAID
                            : WixSDK.Settings.PremiumIntent.FREE);
                        return [2 /*return*/];
                }
            });
        });
    };
    PremiumIntentUpdater.prototype.render = function () {
        return null;
    };
    PremiumIntentUpdater.propTypes = {
        isV3: PropTypes.bool.isRequired,
    };
    return PremiumIntentUpdater;
}(React.Component));
export default PremiumIntentUpdater;
