import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PUB_SUB_RECIPIENTS from 'shared/constants/pub-sub-recipients';
import EVENTS from 'shared/constants/events';
import { getCompId } from 'widget/redux/client/hydrated-data/hydrated-data';
import { withPubSubEvents, consumePubSubEvent, } from 'widget/containers/pub-sub-events';
var SubscriptionCancelHandler = /** @class */ (function (_super) {
    __extends(SubscriptionCancelHandler, _super);
    function SubscriptionCancelHandler() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleSubscriptionCancelEvent = function (_a) {
            var _b = (_a === void 0 ? {} : _a).data, data = _b === void 0 ? {} : _b;
            if (_this.disableConsumeEvents) {
                return;
            }
            var rootCompId = data.rootCompId, recipients = data.recipients;
            var pubSubRecipientName = _this.props.pubSubRecipientName;
            if (recipients) {
                if (!_.includes(recipients, pubSubRecipientName)) {
                    return;
                }
            }
            if (_this.props.compId === rootCompId) {
                _this.unsubscribeSubscriptionEvent();
                _this.props.onSubscriptionCancel();
            }
        };
        return _this;
    }
    SubscriptionCancelHandler.prototype.componentDidUpdate = function (prevProps) {
        consumePubSubEvent(EVENTS.PAYMENT.CANCEL_SUBSCRIPTION, this.handleSubscriptionCancelEvent, {
            prevProps: prevProps,
            nextProps: this.props,
        });
    };
    SubscriptionCancelHandler.prototype.unsubscribeSubscriptionEvent = function () {
        this.disableConsumeEvents = true;
    };
    SubscriptionCancelHandler.prototype.render = function () {
        return null;
    };
    SubscriptionCancelHandler.propTypes = {
        onSubscriptionCancel: PropTypes.func.isRequired,
        pubSubRecipientName: PropTypes.oneOf(_.values(PUB_SUB_RECIPIENTS)),
    };
    SubscriptionCancelHandler = __decorate([
        withPubSubEvents,
        connect(function (state) { return ({ compId: getCompId(state) }); })
    ], SubscriptionCancelHandler);
    return SubscriptionCancelHandler;
}(React.Component));
export default SubscriptionCancelHandler;
