import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import PlayerWrapper from './player-wrapper';
import { VIDEO_PLAYBACK_STATUSES } from '@wix/wix-vod-constants/dist/common/video-playback-statuses';
import { connect } from 'react-redux';
import { handleVideoEndDesktop } from 'widget/redux/client/actions/handle-video-end';
import { isVideoPaused, isVideoPausedOptimistic, isVideoPauseRequested, isVideoPlayAborted, isVideoPlaying, isVideoPlayingOptimistic, isVideoPlayRequested, } from 'widget/selectors/video-playback-status';
import { abortPlayVideo, setPlaybackStatus, } from 'widget/redux/client/actions/player/change-playback-status';
import { setAutoPlayed } from 'widget/redux/client/actions/player/set-autoplayed';
import { isMuted } from 'shared/selectors/player';
var DesktopPlayer = /** @class */ (function (_super) {
    __extends(DesktopPlayer, _super);
    function DesktopPlayer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onPause = function () {
            var _a = _this.props, setPlaybackStatus = _a.setPlaybackStatus, isVideoPaused = _a.isVideoPaused, setAutoPlayed = _a.setAutoPlayed;
            if (!isVideoPaused) {
                setAutoPlayed(false);
                setPlaybackStatus(VIDEO_PLAYBACK_STATUSES.PAUSED);
            }
        };
        _this.onResume = function () {
            var setPlaybackStatus = _this.props.setPlaybackStatus;
            setPlaybackStatus(VIDEO_PLAYBACK_STATUSES.PLAYING);
        };
        _this.onPlayStart = function () {
            var setPlaybackStatus = _this.props.setPlaybackStatus;
            setPlaybackStatus(VIDEO_PLAYBACK_STATUSES.PLAYING);
        };
        _this.onEnded = function () {
            var _a = _this.props, setPlaybackStatus = _a.setPlaybackStatus, onEnded = _a.onEnded;
            setPlaybackStatus(VIDEO_PLAYBACK_STATUSES.ENDED);
            onEnded();
        };
        return _this;
    }
    DesktopPlayer.prototype.render = function () {
        var props = _.omit(this.props, 'mobileMode');
        return (React.createElement(PlayerWrapper, __assign({}, props, { mobileMode: false, onPause: this.onPause, onResume: this.onResume, onPlayStart: this.onPlayStart, onEnded: this.onEnded })));
    };
    DesktopPlayer.propTypes = {
        overlay: PropTypes.any,
        width: PropTypes.number,
        height: PropTypes.number,
        currentTime: PropTypes.number,
        preload: PropTypes.string,
        mainOrThumbnailLocation: PropTypes.string,
        videoUrls: PropTypes.object,
        videoItem: PropTypes.object,
        channelData: PropTypes.object,
        size: PropTypes.object,
        currentSiteUser: PropTypes.object,
        appSettings: PropTypes.object,
        abortPlayVideo: PropTypes.func,
        onFullScreenChanged: PropTypes.func,
        onPlayStart: PropTypes.func,
        onEnded: PropTypes.func,
        reportStats: PropTypes.func,
        fetchVideoUrls: PropTypes.func,
        onPause: PropTypes.func,
        onMemberSignUp: PropTypes.func,
        onSubscribeClick: PropTypes.func,
        onPurchaseClick: PropTypes.func,
        onRentClick: PropTypes.func,
        fetchVideoCards: PropTypes.func,
        onPlayRequest: PropTypes.func,
        cleanupVideoCards: PropTypes.func,
        handleVideoEnd: PropTypes.func,
        setAutoPlayed: PropTypes.func,
        setPlaybackStatus: PropTypes.func,
        muted: PropTypes.bool,
        loop: PropTypes.bool,
        showInitialOverlay: PropTypes.bool,
        isVideoCardsEnabled: PropTypes.bool,
        shouldLoadVideo: PropTypes.bool,
        canShowChannelCover: PropTypes.bool,
        isOverQuota: PropTypes.bool,
        isLive: PropTypes.bool,
        simple: PropTypes.bool,
        isInLightbox: PropTypes.bool,
        isAutoplayed: PropTypes.bool,
        isVideoPlayRequested: PropTypes.bool,
        isVideoPauseRequested: PropTypes.bool,
        isVideoPlaying: PropTypes.bool,
        isVideoPaused: PropTypes.bool,
        isVideoPlayingOptimistic: PropTypes.bool,
        isVideoPausedOptimistic: PropTypes.bool,
        isVideoPlayAborted: PropTypes.bool,
        isMuted: PropTypes.bool,
    };
    DesktopPlayer.defaultProps = {
        onEnded: _.noop,
    };
    DesktopPlayer = __decorate([
        connect(function (state) { return ({
            isVideoPlayRequested: isVideoPlayRequested(state),
            isVideoPauseRequested: isVideoPauseRequested(state),
            isVideoPlaying: isVideoPlaying(state),
            isVideoPaused: isVideoPaused(state),
            isVideoPlayingOptimistic: isVideoPlayingOptimistic(state),
            isVideoPausedOptimistic: isVideoPausedOptimistic(state),
            isVideoPlayAborted: isVideoPlayAborted(state),
            isMuted: isMuted(state),
        }); }, {
            handleVideoEnd: handleVideoEndDesktop,
            setAutoPlayed: setAutoPlayed,
            setPlaybackStatus: setPlaybackStatus,
            abortPlayVideo: abortPlayVideo,
        })
    ], DesktopPlayer);
    return DesktopPlayer;
}(React.Component));
export default DesktopPlayer;
